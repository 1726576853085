/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import Logo from 'components/Logo/Logo';
import { jwtDecode } from 'jwt-decode';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import APIKit from 'services/APIKit';
import RouteKit from 'services/RouteKit';

import SuccessMessage from '../LoginForm/SuccessMessage';

const TokenLoginInfo = ({ error }: { error: boolean }): JSX.Element => {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [newLinkSent, setNewLinkSent] = useState(false);
  const [newLinkError, setNewLinkError] = useState(false);
  const { t } = useTranslation();
  const token = router.query.token?.toString();

  useEffect(() => {
    try {
      const decodedToken = jwtDecode<{ email: string }>(token as string);
      setEmail(decodedToken.email);
      // eslint-disable-next-line no-empty
    } catch (decodeError) {}
  }, [router.query.token]);

  const requestNewLoginLink = async () => {
    try {
      setNewLinkError(false);
      await APIKit.auth.sendMagicLoginLink(email);
      setNewLinkSent(true);
    } catch (newLoginError) {
      setNewLinkSent(true);
      setNewLinkError(true);
    }
  };

  return (
    <div className="bg-white shadow-2 rounded p-5">
      <Logo width={195} className="d-block mx-auto mb-5" />
      {error && !newLinkSent ? (
        <div className="text-center">
          <h1
            style={{ fontSize: '1.5rem' }}
            className="text-gray-700 font-weight-bold"
          >
            {t('login.magic.loginError.title', 'Login link not valid')}
          </h1>
          <p className="text-gray-700">
            {t(
              'login.magic.loginError.text',
              'The link you visited has either expired or the link was not complete. ',
            )}
          </p>
          {!newLinkSent && email && (
            <button
              style={{ borderWidth: '2px' }}
              className="btn btn-red-50 w-100 border-red-100 text-red-900 font-weight-bold mb-4"
              type="button"
              onClick={requestNewLoginLink}
            >
              {' '}
              {t(
                'login.magic.error.requestNewButton',
                'Request New Login Link',
              )}
            </button>
          )}
          <Link
            href={RouteKit.login.index().href}
            as={RouteKit.login.index().as}
            style={{ textDecoration: 'underline' }}
            className="text-red-700"
          >
            {t('general.gotoLogin')}
          </Link>
        </div>
      ) : newLinkSent ? (
        newLinkError ? (
          <>
            <h1
              style={{ fontSize: '1.5rem' }}
              className="text-gray-700 font-weight-bold"
            >
              {t(
                'login.magic.error.title',
                'A problem occurred when sending a new magic link',
              )}
            </h1>
            <p className="text-gray-700">
              {t(
                'login.magic.error.text',
                'We were not able to reach the email address associated with this login link. Please visit the login page where you can either request a new magic link or reset your password.',
              )}
            </p>
            <Link
              href={RouteKit.login.index().href}
              as={RouteKit.login.index().as}
              style={{ textDecoration: 'underline' }}
              className="text-red-700"
            >
              {t('general.gotoLogin')}
            </Link>
          </>
        ) : (
          <SuccessMessage kind="MAGIC" />
        )
      ) : (
        <div
          className="mx-auto d-block spinner-border spinner-border-sm text-red-900"
          role="status"
          data-test="spinner"
        >
          <span className="sr-only">{t('general.loading')}</span>
        </div>
      )}
    </div>
  );
};
export default TokenLoginInfo;
