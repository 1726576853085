import React from 'react';

import { useTranslation } from 'react-i18next';

import LoginFormKinds from './LoginFormKinds.d';

const HelpText = ({ kind }: { kind: LoginFormKinds }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      {kind === 'RESET' && (
        <>
          <p className="mb-0 font-weight-bold text-gray-700">
            {t('general.forgotPassword.title')}
          </p>
          <p className="mb-3 text-gray-500">
            {t('general.forgotPassword.text')}
          </p>
        </>
      )}
      {kind === 'MAGIC' && (
        <>
          <p className="mb-0 font-weight-bold text-gray-700">
            {t('login.magic.title', '')}
          </p>
          <p className="mb-3 text-gray-500">{t('login.magic.text', '')}</p>
        </>
      )}
      {kind === 'LOGIN' && (
        <>
          <p className="mb-0 font-weight-bold text-gray-700">
            {t('login.emailPassword.title', '')}
          </p>
          <p className="mb-3 text-gray-500">
            {t('login.emailPassword.text', '')}
          </p>
        </>
      )}
    </div>
  );
};
export default HelpText;
