import React from 'react';

import { useTranslation } from 'react-i18next';

import LoginFormKinds from './LoginFormKinds.d';

type ErrorMessageProps = { kind: LoginFormKinds };

const ErrorMessage = ({ kind }: ErrorMessageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="bg-red-50 border border-red-300 p-3 rounded text-red-900 mb-3">
      {kind === 'RESET' && (
        <>
          <p className="mb-0 font-weight-bold">
            {t('general.forgotPassword.title')}
          </p>
          <p className="mb-0">{t('general.forgotPassword.text')}</p>
        </>
      )}
      {kind === 'MAGIC' && (
        <>
          <h1 style={{ fontSize: '1.5rem' }} className="font-weight-bold">
            {t(
              'login.magic.sendError.title',
              'A problem occurred when sending a new magic link',
            )}
          </h1>
          <p className="">
            {t(
              'login.magic.sendError.text',
              'We were not able to reach the email address associated with this login link. Please check that the email address is spelled correctly and request a new magic link or reset your password.',
            )}
          </p>
        </>
      )}
      {kind === 'LOGIN' && (
        <>
          <p className="mb-0 font-weight-bold ">
            {t('login.emailPassword.error.title', 'Could not login')}
          </p>
          <p className="mb-0">
            {t('login.magic.emailPassword.text', 'Email or password is wrong.')}
          </p>
        </>
      )}
    </div>
  );
};
export default ErrorMessage;
