import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import APIKit from 'services/APIKit';
import { queries as SWRCountryQueries } from 'services/APIKit/SWR/country';
import { CountryType } from 'services/APIKit/types';
import { logError } from 'services/datadog';
import { privacyPolicies } from 'services/helpers';
import { login, setAuthTokens } from 'services/withAuth';
import useSWR from 'swr';

import LoginForm from './components/LoginForm/LoginForm';
import TokenLoginInfo from './components/TokenLoginInfo';
import styles from './Login.module.scss';

const {
  getSettingsForCountry: { key: GET_COUNTRY, fetcher: getSettingsForCountry },
} = SWRCountryQueries;

type CountryResponse = {
  status: number;
  data: CountryType | '';
};

const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const { query } = useRouter();
  const host = window.location.hostname;
  const locale = host.match(/nl|se|es|no|fr|de|dk/g)?.toString() || 'en';
  const { data: { status, data: countrySettings } = {} } = useSWR<
    any,
    CountryResponse
  >(GET_COUNTRY, (url: string) => getSettingsForCountry(url, host), {
    suspense: false,
  });
  const languageSelector =
    status !== 204 && !!countrySettings?.useLanguageSelector;
  const { languages } = countrySettings || {};

  const accessToken = query.accessToken?.toString();
  const refreshToken = query.refreshToken?.toString();
  const [tokenLoginError, setTokenLoginError] = useState(false);

  useEffect(() => {
    if (accessToken && refreshToken) {
      const magicLinkAccesAndRefresToken = {
        access: accessToken,
        refresh: refreshToken,
      };
      setAuthTokens(magicLinkAccesAndRefresToken);
      const validToken = async () => {
        let tokenCheck = false;
        try {
          setTokenLoginError(false);
          const { data } = await APIKit.auth.verifyToken(accessToken);
          tokenCheck = true;
        } catch (error) {
          setTokenLoginError(true);
          logError({ error });
        }
        if (tokenCheck) {
          login(accessToken);
        }
      };
      validToken();
    }
  }, [accessToken, refreshToken]);

  return (
    <>
      <Head>
        <title>{t('pageTitle.login')}</title>
      </Head>
      <div className={`mx-auto mt-5 ${styles.loginContainer}`}>
        {languageSelector && (
          <div
            style={{ marginBottom: `calc(-${30 * languages.length}px - 1rem)` }}
            className={styles.languageSwitcherWrapper}
          >
            <span className={styles.languageToggleButton}>
              <i
                className={classNames(
                  'fas',
                  'fa-language',
                  styles.languageToggleIcon,
                )}
              />
              {t('languageSwitcher.menuItem.label')}
            </span>
            <LanguageSwitcher
              componentClass={styles.LanguageSwitcher}
              context="login"
              languages={languages}
              isLoggedOut
            />
          </div>
        )}
        {accessToken ? (
          <TokenLoginInfo error={tokenLoginError} />
        ) : (
          <LoginForm />
        )}
        <small className="px-5 d-block text-gray-500 text-center">
          {t('general.privacyPolicy.message')}
          <a
            href={privacyPolicies[locale]}
            className="pl-1 text-gray-900"
            target="_blank"
            rel="noreferrer"
          >
            {t('general.privacyPolicy.link')}
          </a>
        </small>
      </div>

      <img
        className={`d-none d-md-block ${styles.backgroundImage}`}
        src="/img/svg/clinic.svg"
        alt=""
      />
    </>
  );
};

export default Login;
