import React, { useState } from 'react';

import { ErrorMessage, Field } from 'formik';

import styles from './LoginForm.module.scss';

const FieldSet = ({
  error,
  label,
  placeholder,
  field,
}: {
  error: boolean;
  label: string;
  placeholder: string;
  field: 'email' | 'password';
}): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <fieldset className="mb-3">
      <label htmlFor={field} className="font-weight-bold mb-0 text-gray-800">
        {label}
      </label>

      <div className="position-relative">
        <Field
          className={`${styles.input} ${
            error ? 'bg-red-50 border-red-300' : 'bg-gray-50'
          } w-100 p-2 rounded`}
          id={field}
          name={field}
          placeholder={placeholder}
          type={field === 'password' && showPassword ? 'text' : field}
        />
        {field === 'password' && (
          <button
            style={{
              right: '0',
              top: '0',
              bottom: '0',
              background: 'transparent',
              border: 'none',
            }}
            className="position-absolute px-3"
            type="button"
            onClick={() => {
              setShowPassword(prev => !prev);
            }}
          >
            {showPassword ? (
              <i className="fas fa-eye-slash" />
            ) : (
              <i className="fas fa-eye" />
            )}
          </button>
        )}
      </div>
      <ErrorMessage name={field} className="text-red-700" component="p" />
    </fieldset>
  );
};

export default FieldSet;
