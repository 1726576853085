import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './LoginForm.module.scss';
import LoginFormKinds from './LoginFormKinds.d';

const SubmitButton = ({
  kind,
  isSubmitting,
}: {
  kind: LoginFormKinds;
  isSubmitting: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  const translationKey = {
    MAGIC: ['login.magic.submit', 'Send me a magic login link'],
    LOGIN: ['login.emailPassword.submit', 'Login'],
    RESET: ['login.reset.submit', 'Send reset link'],
  };

  return (
    <button
      disabled={isSubmitting}
      type="submit"
      style={{ borderWidth: '2px' }}
      className={`${styles.button} w-100 p-2 border-red-100 btn btn-red-50 text-red-900 font-weight-bold`}
    >
      {isSubmitting ? (
        <div
          className="spinner-border spinner-border-sm text-red-900"
          role="status"
          data-test="spinner"
        >
          <span className="sr-only">{t('general.loading')}</span>
        </div>
      ) : (
        t(translationKey[kind])
      )}
    </button>
  );
};
export default SubmitButton;
