import React from 'react';

import { useTranslation } from 'react-i18next';

const SuccessMessage = ({
  kind,
}: {
  kind: 'MAGIC' | 'LOGIN' | 'RESET';
}): JSX.Element => {
  const { t } = useTranslation();

  const texts = {
    MAGIC: {
      title: ['login.magic.success.title'],
      text: ['login.magic.success.text'],
    },
    RESET: {
      title: ['login.reset.success.title'],
      text: ['resetPassword.success'],
    },
  };

  return (
    <div className="text-center">
      <img
        height="200"
        className="d-block mx-auto"
        src="/img/login.png"
        alt=""
      />
      <h1
        style={{ fontSize: '1.5rem' }}
        className="font-weight-bold text-gray-800"
      >
        {t(texts[kind].title)}
      </h1>
      <p className="text-gray-700">{t(texts[kind].text)}</p>
    </div>
  );
};
export default SuccessMessage;
