import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import Logo from 'components/Logo/Logo';
import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import APIKit from 'services/APIKit';
import { logError } from 'services/datadog';
import * as yup from 'yup';

import ErrorMessage from './ErrorMessage';
import FieldSet from './FieldSet';
import FormTypeSelectors from './FormTypeSelectors';
import HelpText from './HelpText';
import styles from './LoginForm.module.scss';
import LoginFormKinds from './LoginFormKinds.d';
import SubmitButton from './SubmitButton';
import SuccessMessage from './SuccessMessage';

type STATUSES = 'PENDING' | 'SUCCESS' | 'ERROR';

const LoginForm = (): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();

  const [kind, setKind] = useState<LoginFormKinds>('LOGIN');
  const [status, setStatus] = useState<STATUSES>('PENDING');

  const yupEmail = yup
    .string()
    .email(t('general.mustBeValidEmail'))
    .required(t('general.required'));
  const emailSchema = yup.object().shape({
    email: yupEmail,
  });

  const emailPasswordLoginSchema = yup.object().shape({
    email: yupEmail,
    password: yup.string().required(t('general.required')),
  });

  useEffect(() => {
    setStatus('PENDING');
  }, [kind]);

  const handleSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    switch (kind) {
      case 'MAGIC':
        try {
          await APIKit.auth.sendMagicLoginLink(email);
          setStatus('SUCCESS');
        } catch (error) {
          setStatus('ERROR');
        }
        break;
      case 'LOGIN':
        try {
          let nextQuery = router.query.next
            ? router.query.next.toString()
            : undefined;
          nextQuery =
            !nextQuery || nextQuery.includes('index') ? '/' : nextQuery;
          await APIKit.auth.createAccessAndRefreshToken({
            email,
            password,
            nextQuery,
          });
        } catch (error: any) {
          const e = error as AxiosError;
          // Don't log wrong password
          if (e.response?.status !== 401) {
            // Clean out user password if it is in the data.
            const parse = e.response?.config.data.match(
              /["|']password["|']:?["|'](?<pass>.([^"]|\\")*)["|']/,
            );
            let trackData = {};
            if (parse) {
              const cleaned = JSON.stringify(e).replace(
                parse.groups.pass,
                '***',
              );
              trackData = JSON.parse(cleaned);
            }
            logError({ error: trackData });
          }
          setStatus('ERROR');
        }
        break;
      case 'RESET':
        try {
          await APIKit.auth.resetPassword(email);
          setStatus('SUCCESS');
        } catch (error) {
          logError({ error });
          setStatus('ERROR');
        }
        break;
      default:
        break;
    }
  };
  return (
    <div className={styles.loginFormWrapper}>
      {status !== 'SUCCESS' ? (
        <>
          <Logo width={195} className="d-block mx-auto mb-5" />
          <HelpText kind={kind} />
          {status === 'ERROR' && <ErrorMessage kind={kind} />}
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={handleSubmit}
            validationSchema={
              kind === 'LOGIN' ? emailPasswordLoginSchema : emailSchema
            }
          >
            {({ isSubmitting, errors, touched, setTouched, setErrors }) => (
              <>
                <Form className={`${styles.form} mb-4`}>
                  <FieldSet
                    field="email"
                    label={t('general.email.label', 'Email')}
                    error={
                      (errors.email !== undefined && touched.email) ?? false
                    }
                    placeholder={t(
                      'login.email.placeholder',
                      'Enter your email address...',
                    )}
                  />
                  {kind === 'LOGIN' && (
                    <FieldSet
                      field="password"
                      label={t('general.password.label', 'Password')}
                      error={
                        (errors.password !== undefined && touched.password) ??
                        false
                      }
                      placeholder={t(
                        'login.password.placeholder',
                        'Enter your password...',
                      )}
                    />
                  )}
                  <SubmitButton
                    kind={kind}
                    isSubmitting={isSubmitting && status === 'PENDING'}
                  />
                </Form>
                <FormTypeSelectors kind={kind} setKind={setKind} />
              </>
            )}
          </Formik>
        </>
      ) : (
        <SuccessMessage kind={kind} />
      )}
    </div>
  );
};
export default LoginForm;
