import HTTPKit from '../HTTPKit';

export const queries = {
  getSettingsForCountry: {
    key: '/api/v1/country-settings/',
    fetcher: async (url: string, domain: string) => HTTPKit.get(url),
  },
};

const SWRCountry = { queries };

export default SWRCountry;
