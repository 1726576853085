import React, { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import LoginFormKinds from './LoginFormKinds.d';

const FormTypeSelectors = ({
  kind,
  setKind,
}: {
  kind: string;
  setKind: Dispatch<SetStateAction<LoginFormKinds>>;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="text-center text-gray-500">
      {kind !== 'RESET' && (
        <button
          onClick={() => {
            setKind('RESET');
          }}
          type="button"
          style={{ textDecoration: 'underline' }}
          className="btn btn-link text-red-700 mb-3"
        >
          {t('login.reset.link', 'Forgot password?')}
        </button>
      )}
      <p>
        {t('login.loginAlternativeText', 'You can also login with')}
        {kind === 'MAGIC' ? (
          <button
            onClick={() => {
              setKind('LOGIN');
            }}
            type="button"
            style={{ textDecoration: 'underline' }}
            className="d-inline border-0  btn-link text-red-700"
          >
            {t('login.emailPassword.link', 'email and password.')}
          </button>
        ) : (
          <button
            onClick={() => {
              setKind('MAGIC');
            }}
            type="button"
            style={{ textDecoration: 'underline' }}
            className="d-inline border-0  btn-link text-red-700"
          >
            {t('login.magic.link', 'magic link.')}
          </button>
        )}
      </p>
    </div>
  );
};
export default FormTypeSelectors;
